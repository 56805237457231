import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { injectMutation } from '@tanstack/angular-query-experimental';
import { CreateMutationResult } from '@tanstack/angular-query-experimental/src/types';
import { LocalStorageService } from 'ngx-webstorage';
import { lastValueFrom } from 'rxjs';
import appVersion from '@/../../app_version.json';
import { User } from '@/pages/auth/models';
import { LoginRequestDTO } from '@/pages/auth/models/login.model';
import { AuthHttpService } from '@/pages/auth/services/http/auth-http.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authLocalStorageToken = `${appVersion.version}-${environment.appEnv}-${environment.USERDATA_KEY}`;

  constructor(
    private _authHttpService: AuthHttpService,
    private _storage: LocalStorageService,
    private _router: Router,
  ) {
  }

  login(): CreateMutationResult<User, HttpErrorResponse, LoginRequestDTO> {
    return injectMutation<User, HttpErrorResponse, LoginRequestDTO>(() => ({
      mutationFn: variables => lastValueFrom(this._authHttpService.login(variables)),
      onSuccess: data => this._storage.store(this._authLocalStorageToken, data),
    }));
  }

  logout(): void {
    this._storage.clear(this._authLocalStorageToken);
    this._router.navigate(['/auth/login']).then(r => {
      !r && console.error('Navigation failed in auth.service/logout');
    });
  }

  isLogged(): boolean {
    // TODO: Implement a better way to check if the user is logged in, for example if token still has lifetime
    return !!this._storage.retrieve(this._authLocalStorageToken);
  }

  getUser(): User | null {
    return this._storage.retrieve(this._authLocalStorageToken);
  }
}
