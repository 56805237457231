import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '@/layout/services/layout.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
  model: MenuItem[] = [];

  constructor(public layoutService: LayoutService) {
  }

  ngOnInit(): void {
    this.model = [
      {
        label: 'LAYOUT.MENU.HOME.TITLE',
        items: [
          { label: 'LAYOUT.MENU.HOME.DASHBOARD', icon: 'pi pi-fw pi-home', routerLink: ['/'] },
        ],
      },
      {
        label: 'LAYOUT.MENU.PAGES.TITLE',
        icon: 'pi pi-fw pi-briefcase',
        items: [
          {
            label: 'LAYOUT.MENU.PAGES.LANDING',
            icon: 'pi pi-fw pi-globe',
            routerLink: ['/landing'],
          },
          {
            label: 'LAYOUT.MENU.PAGES.AUTH.TITLE',
            icon: 'pi pi-fw pi-user',
            items: [
              {
                label: 'LAYOUT.MENU.PAGES.AUTH.LOGIN',
                icon: 'pi pi-fw pi-sign-in',
                routerLink: ['/auth/login'],
              },
              {
                label: 'LAYOUT.MENU.PAGES.AUTH.ERROR',
                icon: 'pi pi-fw pi-times-circle',
                routerLink: ['/errors/error'],
              },
              {
                label: 'LAYOUT.MENU.PAGES.AUTH.ACCESS_DENIED',
                icon: 'pi pi-fw pi-lock',
                routerLink: ['/errors/access'],
              },
            ],
          },
          {
            label: 'LAYOUT.MENU.PAGES.NOT_FOUND',
            icon: 'pi pi-fw pi-exclamation-circle',
            routerLink: ['/errors/not_found'],
          },
          {
            label: 'LAYOUT.MENU.PAGES.EMPTY',
            icon: 'pi pi-fw pi-circle-off',
            routerLink: ['/empty'],
          },
          {
            label: 'LAYOUT.MENU.PAGES.PAGINATION',
            icon: 'pi pi-fw pi-list',
            items: [
              {
                label: 'LAYOUT.MENU.PAGES.LIST',
                icon: 'pi pi-fw pi-list',
                routerLink: ['/pagination/list'],
              },
              {
                label: 'LAYOUT.MENU.PAGES.TABLE',
                icon: 'pi pi-fw pi-table',
                routerLink: ['/pagination/table'],
              },
            ],
          },
        ],
      },
      {
        label: 'LAYOUT.MENU.GET_STARTED.TITLE',
        visible: !environment.production,
        items: [
          {
            label: 'LAYOUT.MENU.GET_STARTED.DOCUMENTATION',
            icon: 'pi pi-fw pi-question',
            routerLink: ['/documentation'],
          },
          {
            label: 'LAYOUT.MENU.GET_STARTED.VIEW_SOURCE',
            icon: 'pi pi-fw pi-search',
            url: 'https://github.com/DamirGooApps/primeng-template/',
            target: '_blank',
          },
          {
            label: 'LAYOUT.MENU.UI_COMPONENTS.EXAMPLES',
            icon: 'pi pi-fw pi-table',
            items: [
              {
                label: 'LAYOUT.MENU.UI_COMPONENTS.CRUD',
                icon: 'pi pi-fw pi-pencil',
                routerLink: ['/pages/crud'],
              },
              {
                label: 'LAYOUT.MENU.UI_COMPONENTS.TIMELINE',
                icon: 'pi pi-fw pi-calendar',
                routerLink: ['/pages/timeline'],
              },
              {
                label: 'LAYOUT.MENU.UI_COMPONENTS.FORM_LAYOUT',
                icon: 'pi pi-fw pi-id-card',
                routerLink: ['/uikit/formlayout'],
              },
              {
                label: 'LAYOUT.MENU.UI_COMPONENTS.INPUT',
                icon: 'pi pi-fw pi-check-square',
                routerLink: ['/uikit/input'],
              },
              {
                label: 'LAYOUT.MENU.UI_COMPONENTS.FLOAT_LABEL',
                icon: 'pi pi-fw pi-bookmark',
                routerLink: ['/uikit/floatlabel'],
              },
              {
                label: 'LAYOUT.MENU.UI_COMPONENTS.INVALID_STATE',
                icon: 'pi pi-fw pi-exclamation-circle',
                routerLink: ['/uikit/invalidstate'],
              },
              { label: 'LAYOUT.MENU.UI_COMPONENTS.BUTTON', icon: 'pi pi-fw pi-box', routerLink: ['/uikit/button'] },
              { label: 'LAYOUT.MENU.UI_COMPONENTS.TABLE', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table'] },
              { label: 'LAYOUT.MENU.UI_COMPONENTS.LIST', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list'] },
              { label: 'LAYOUT.MENU.UI_COMPONENTS.TREE', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree'] },
              { label: 'LAYOUT.MENU.UI_COMPONENTS.PANEL', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel'] },
              { label: 'LAYOUT.MENU.UI_COMPONENTS.OVERLAY', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay'] },
              { label: 'LAYOUT.MENU.UI_COMPONENTS.MEDIA', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media'] },
              {
                label: 'LAYOUT.MENU.UI_COMPONENTS.MENU',
                icon: 'pi pi-fw pi-bars',
                routerLink: ['/uikit/menu'],
                routerLinkActiveOptions: {
                  paths: 'subset',
                  queryParams: 'ignored',
                  matrixParams: 'ignored',
                  fragment: 'ignored',
                },
              },
              { label: 'LAYOUT.MENU.UI_COMPONENTS.MESSAGE', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message'] },
              { label: 'LAYOUT.MENU.UI_COMPONENTS.FILE', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file'] },
              { label: 'LAYOUT.MENU.UI_COMPONENTS.CHART', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts'] },
              { label: 'LAYOUT.MENU.UI_COMPONENTS.MISC', icon: 'pi pi-fw pi-circle', routerLink: ['/uikit/misc'] },
            ],
          },
        ],
      },
      {
        label: 'LAYOUT.MENU.UTILITIES.TITLE',
        visible: !environment.production,
        items: [
          {
            label: 'LAYOUT.MENU.UTILITIES.PRIMENG',
            icon: 'pi pi-fw pi-prime',
            url: 'https://primeng.org/',
            target: '_blank',
          },
          {
            label: 'LAYOUT.MENU.UTILITIES.PRIME_BLOCKS',
            icon: 'pi pi-fw pi-eye',
            routerLink: ['/blocks'],
          },
          {
            label: 'LAYOUT.MENU.UTILITIES.PRIME_ICONS',
            icon: 'pi pi-fw pi-prime',
            url: 'https://primeng.org/icons',
            target: '_blank',
          },
          {
            label: 'LAYOUT.MENU.UTILITIES.PRIME_FLEX',
            icon: 'pi pi-fw pi-desktop',
            url: 'https://www.primefaces.org/primeflex/',
            target: '_blank',
          },
          {
            label: 'LAYOUT.MENU.UTILITIES.APEX_CHARTS',
            icon: 'pi pi-fw pi-chart-bar',
            url: 'https://apexcharts.com/',
            target: '_blank',
          },
        ],
      },
    ];
  }
}
