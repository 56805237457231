import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ProdGuard } from '@/common/guards';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@/pages/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'errors',
    loadChildren: () => import('@/pages/errors/errors.module').then(m => m.ErrorsModule),
  },
  {
    path: 'landing',
    loadChildren: () => import('@/pages/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('@/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'empty',
        loadChildren: () => import('@/pages/empty/empty-demo.module').then(m => m.EmptyDemoModule),
      },
      {
        path: 'pagination',
        loadChildren: () => import('@/pages/pagination/pagination-demo.module').then(m => m.PaginationDemoModule),
      },
      {
        path: 'uikit',
        loadChildren: () => import('./demo/components/uikit/uikit.module').then(m => m.UIkitModule),
        canActivate: [ProdGuard],
      },
      {
        path: 'documentation',
        loadChildren: () => import('@/pages/documentation/documentation.module').then(m => m.DocumentationModule),
        canActivate: [ProdGuard],
      },
      {
        path: 'blocks',
        loadChildren: () => import('./demo/components/primeblocks/primeblocks.module').then(m => m.PrimeBlocksModule),
        canActivate: [ProdGuard],
      },
      {
        path: 'pages',
        loadChildren: () => import('./demo/components/pages/demo-pages.module').then(m => m.DemoPagesModule),
        canActivate: [ProdGuard],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/errors/not_found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
