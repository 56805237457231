<div [ngClass]='containerClass' class='layout-wrapper'>
  <app-navbar />
  <div class='layout-sidebar'>
    <app-sidebar />
  </div>
  <p-toast />
  <div class='layout-main-container'>
    <div class='layout-main'>
      <router-outlet />
    </div>
    <app-footer />
  </div>
  <app-config />
  <div class='layout-mask'></div>
</div>
