import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginRequestDTO, User } from '@/pages/auth/models';
import { environment } from '@environments/environment';

const API_URL = `${environment.apiUrl}/user`;

@Injectable({ providedIn: 'root' })
export class AuthHttpService {
  constructor(private _http: HttpClient) {
  }

  login(loginDto: LoginRequestDTO): Observable<User> {
    return this._http.post<User>(`${API_URL}/login`, loginDto);
  }
}
