import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '@/layout/services/layout.service';
import { TranslationService } from '@/modules/i18n';
import { AuthService } from '@/pages/auth/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  items: MenuItem[];

  @ViewChild('menuButton') menuButton!: ElementRef;
  @ViewChild('navbarMenuButton') navbarMenuButton!: ElementRef;
  @ViewChild('navbarMenu') menu!: ElementRef;

  constructor(
    protected layoutService: LayoutService,
    private _authService: AuthService,
    private _translationService: TranslationService,
  ) {
  }

  ngOnInit(): void {
    this.items = [
      {
        label: 'Profile',
        icon: 'pi pi-fw pi-user',
        command: () => this.onProfileButtonClick(),
      },
      {
        label: 'Change lang',
        icon: 'pi pi-fw pi-language',
        command: () => this._translationService.toggleLanguage(),
      },
      {
        label: 'Log out',
        icon: 'pi pi-fw pi-power-off',
        command: () => this._authService.logout(),
      },
    ];
  }

  onProfileButtonClick(): void {
  }
}
