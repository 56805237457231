import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageService as PrimeNGMessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { FormErrorsComponent } from '@/common/components/form-errors/form-errors.component';
import { DatePipe, ValidationErrorsToArrayPipe } from '@/common/pipes';
import { TranslationModule } from '@/modules/i18n';
import { ConfigComponent } from './components/config/config.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SidebarModule,
    RadioButtonModule,
    ButtonModule,
    InputSwitchModule,
    TranslationModule,
    ToastModule,
    SkeletonModule,
  ],
  declarations: [
    ConfigComponent,
    FormErrorsComponent,
    DatePipe,
    ValidationErrorsToArrayPipe,
  ],
  exports: [
    ConfigComponent,
    FormErrorsComponent,
    DatePipe,
    ValidationErrorsToArrayPipe,
  ],
  providers: [
    PrimeNGMessageService,
  ],
})
export class AppCommonModule {
}
