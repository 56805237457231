import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpBackend } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export const languages = ['es', 'en'];
export const languageKeys = ['es-ES', 'en-US'];
export const defaultLang = 'en';

@NgModule({
  imports: [CommonModule, TranslateModule],
  exports: [TranslateModule],
  providers: [
    languageKeys.map(lang => ({ provide: LOCALE_ID, useValue: lang })),
  ],
})
export class TranslationModule {
  constructor() {
    registerLocaleData(localeEs);
    registerLocaleData(localeEn);
  }
}

export function HttpLoaderFactory(httpBackend: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(httpBackend, [
    './assets/i18n/common/',
    './assets/i18n/layout/',
    './assets/i18n/auth/',
    './assets/i18n/errors/',
  ]);
}
