import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as sentry from '@sentry/angular-ivy';
import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental';
import { provideAngularQuery, QueryClient } from '@tanstack/angular-query-experimental';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HttpLoaderFactory } from '@/modules/i18n';
import { AuthenticationInterceptor } from '@/pages/auth/interceptors/authentication.interceptor';
import { environment } from '@environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    LayoutModule,
    NgxWebstorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    AngularQueryDevtools,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    environment.production ? [
      {
        provide: ErrorHandler,
        useValue: sentry.createErrorHandler({ showDialog: true }),
      },
      {
        provide: sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {
        },
        deps: [sentry.TraceService],
        multi: true,
      },
    ] : [],
    provideHttpClient(),
    provideAngularQuery(new QueryClient()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
