import { Component } from '@angular/core';
import appVersion from '@/../../app_version.json';
import { LayoutService } from '@/layout/services/layout.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  version = 'v' + appVersion.version + '-' + environment.appEnv;

  constructor(public layoutService: LayoutService) {
  }
}

