<div class='layout-navbar'>
  <button #menuButton (click)='layoutService.onMenuToggle()' class='p-link layout-menu-button layout-navbar-button'>
    <i class='pi pi-bars'></i>
  </button>

  <a class='layout-navbar-logo' routerLink=''>
    <img alt='logo'
         src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo-black' : 'logo-white'}}.svg" />
    <!--    <span>{{ 'LAYOUT.NAVBAR.TITLE' | translate }}</span>-->
  </a>

  <button #navbarMenuButton class='p-link layout-navbar-menu-button layout-navbar-button'
          (click)='layoutService.showProfileSidebar()'>
    <i class='pi pi-ellipsis-v'></i>
  </button>

  <div #navbarMenu [ngClass]="{'layout-navbar-menu-mobile-active': layoutService.state.profileSidebarVisible}"
       class='layout-navbar-menu'>
    <button class='p-link layout-navbar-button'>
      <i class='pi pi-calendar'></i>
      <span>{{ 'LAYOUT.NAVBAR.CALENDAR' | translate }}</span>
    </button>
    <p-menu #menu [model]='items' [popup]='true' />
    <button class='p-link layout-navbar-button' (click)='menu.toggle($event)'>
      <i class='pi pi-user'></i>
      <span>{{ 'LAYOUT.NAVBAR.PROFILE' | translate }}</span>
    </button>
    <button [routerLink]="'/documentation'" class='p-link layout-navbar-button'>
      <i class='pi pi-cog'></i>
      <span>{{ 'LAYOUT.NAVBAR.SETTINGS' | translate }}</span>
    </button>
  </div>
</div>
