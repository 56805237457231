import { Component, Input } from '@angular/core';
import { LayoutService } from '@/layout/services/layout.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent {
  @Input() minimal: boolean = false;

  scales: number[] = [12, 13, 14, 15, 16];

  constructor(
    protected layoutService: LayoutService,
  ) {
  }

  get visible(): boolean {
    return this.layoutService.state.configSidebarVisible;
  }

  set visible(value: boolean) {
    this.layoutService.state.configSidebarVisible = value;
  }

  get scale(): number {
    return this.layoutService.config().scale;
  }

  set scale(value: number) {
    this.layoutService.config.update(config => ({
      ...config,
      scale: value,
    }));
  }

  get menuMode(): string {
    return this.layoutService.config().menuMode;
  }

  set menuMode(value: string) {
    this.layoutService.config.update(config => ({
      ...config,
      menuMode: value,
    }));
  }

  get inputStyle(): string {
    return this.layoutService.config().inputStyle;
  }

  set inputStyle(value: string) {
    this.layoutService.config().inputStyle = value;
  }

  get ripple(): boolean {
    return this.layoutService.config().ripple;
  }

  set ripple(value: boolean) {
    this.layoutService.config.update(config => ({
      ...config,
      ripple: value,
    }));
  }

  get theme(): string {
    return this.layoutService.config().theme;
  }

  set theme(val: string) {
    this.layoutService.config.update(config => ({
      ...config,
      theme: val,
    }));
  }

  get colorScheme(): string {
    return this.layoutService.config().colorScheme;
  }

  set colorScheme(val: string) {
    this.layoutService.config.update(config => ({
      ...config,
      colorScheme: val,
    }));
  }

  onConfigButtonClick(): void {
    this.layoutService.showConfigSidebar();
  }

  changeTheme(theme: string, colorScheme: string): void {
    this.theme = theme;
    this.colorScheme = colorScheme;
  }

  decrementScale(): void {
    this.scale--;
  }

  incrementScale(): void {
    this.scale++;
  }
}
