<button (click)='onConfigButtonClick()' class='layout-config-button p-link' type='button'>
  <i class='pi pi-cog'></i>
</button>

<p-sidebar [(visible)]='visible' [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'" position='right'
           styleClass='layout-config-sidebar w-20rem'>
  <h5>{{ "COMMON.COMPONENTS.CONFIG.SCALE" | translate }}</h5>
  <div class='flex align-items-center'>
    <button (click)='decrementScale()' [disabled]='scale === scales[0]'
            class='p-button-text p-button-rounded w-2rem h-2rem mr-2'
            icon='pi pi-minus'
            pButton type='button'></button>
    <div class='flex gap-2 align-items-center'>
      @for (s of scales; track $index) {
        <i [ngClass]="{'text-primary-500': s === scale}" class='pi pi-circle-fill text-300'></i>
      }
    </div>
    <button (click)='incrementScale()' [disabled]='scale === scales[scales.length - 1]'
            class='p-button-text p-button-rounded w-2rem h-2rem ml-2' icon='pi pi-plus' pButton
            type='button'></button>
  </div>

  @if (!minimal) {
    <h5>{{ "COMMON.COMPONENTS.CONFIG.MENU_TYPE.TITLE" | translate }}</h5>
    <div class='field-radiobutton'>
      <p-radioButton [(ngModel)]='menuMode' inputId='mode1' name='menuMode' value='static' />
      <label [for]='"mode1"'>{{ "COMMON.COMPONENTS.CONFIG.MENU_TYPE.STATIC" | translate }}</label>
    </div>
    <div class='field-radiobutton'>
      <p-radioButton [(ngModel)]='menuMode' inputId='mode2' name='menuMode' value='overlay' />
      <label [for]='"mode2"'>{{ "COMMON.COMPONENTS.CONFIG.MENU_TYPE.OVERLAY" | translate }}</label>
    </div>
  }

  @if (!minimal) {
    <h5>{{ "COMMON.COMPONENTS.CONFIG.INPUT_STYLE.TITLE" | translate }}</h5>
    <div class='flex'>
      <div class='field-radiobutton flex-1'>
        <p-radioButton [(ngModel)]='inputStyle' inputId='outlined_input' name='inputStyle' value='outlined' />
        <label [for]='"outlined_input"'>{{ "COMMON.COMPONENTS.CONFIG.INPUT_STYLE.OUTLINED" | translate }}</label>
      </div>
      <div class='field-radiobutton flex-1'>
        <p-radioButton [(ngModel)]='inputStyle' inputId='filled_input' name='inputStyle' value='filled' />
        <label [for]='"filled_input"'>{{ "COMMON.COMPONENTS.CONFIG.INPUT_STYLE.FILLED" | translate }}</label>
      </div>
    </div>

    <h5>{{ "COMMON.COMPONENTS.CONFIG.RIPPLE_EFFECT" | translate }}</h5>
    <p-inputSwitch [(ngModel)]='ripple' />
  }

  <h5>{{ "COMMON.COMPONENTS.CONFIG.THEME" | translate }}</h5>
  <div class='grid'>
    <div class='col-4'>
      <button (click)="changeTheme('lara-light-blue', 'light')" class='p-link w-2rem h-2rem'>
        <img alt='Lara Light Blue' class='w-2rem h-2rem'
             src='/assets/layout/images/themes/lara-light-blue.png' />
      </button>
    </div>
    <div class='col-4'>
      <button (click)="changeTheme('lara-light-purple', 'light')" class='p-link w-2rem h-2rem'>
        <img alt='Lara Light Purple' class='w-2rem h-2rem'
             src='/assets/layout/images/themes/lara-light-purple.png' />
      </button>
    </div>
    <div class='col-4'>
      <button (click)="changeTheme('lara-light-teal', 'light')" class='p-link w-2rem h-2rem'>
        <img alt='Lara Light Teal' class='w-2rem h-2rem'
             src='/assets/layout/images/themes/lara-light-teal.png' />
      </button>
    </div>
    <div class='col-4'>
      <button (click)="changeTheme('lara-dark-blue', 'dark')" class='p-link w-2rem h-2rem'>
        <img alt='Lara Dark Blue' class='w-2rem h-2rem'
             src='/assets/layout/images/themes/lara-dark-blue.png' />
      </button>
    </div>
    <div class='col-4'>
      <button (click)="changeTheme('lara-dark-purple', 'dark')" class='p-link w-2rem h-2rem'>
        <img alt='Lara Dark Purple' class='w-2rem h-2rem'
             src='/assets/layout/images/themes/lara-dark-purple.png' />
      </button>
    </div>
    <div class='col-4'>
      <button (click)="changeTheme('lara-dark-teal', 'dark')" class='p-link w-2rem h-2rem'>
        <img alt='Lara Dark Teal' class='w-2rem h-2rem'
             src='/assets/layout/images/themes/lara-dark-teal.png' />
      </button>
    </div>
  </div>
</p-sidebar>
